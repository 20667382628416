@import 'variables/color';

.ct-app {
  mat-progress-bar {
    .mat-progress-bar-buffer {
      background-color: $light-grey !important;
    }

    &.mat-primary .mat-progress-bar-fill::after {
      background-color: $dark-green !important;
    }
  }
}
