@import 'variables/color';

.ct-app {
  ct-gallery {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: block;
    height: 600px;
    background-color: transparent;
    user-select: none;
    * {
      box-sizing: border-box;
    }
  }

  ct-gallery,
  ct-gallery-core {
    position: relative;
    overflow: hidden;
  }

  ct-gallery-core,
  .g-box,
  .g-slider {
    display: flex;
    height: 100%;
    width: 100%;
  }

  ct-gallery[fluid] {
    transform: translateX(-50vw);
    width: 100vw;
    left: 50%;
    &[fluid='false'] {
      transform: none;
      width: initial;
      left: initial;
    }
  }

  .g-no-transition {
    transition: unset !important;
  }

  .g-box,
  ct-gallery-slider {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    order: 1;
    height: 100%;
  }

  ct-gallery-nav,
  .g-btn-close,
  .g-btn-edit {
    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 0 1px black);
      transition: opacity linear 0.2s;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
}
