@mixin form-field-custom-color($theme) {
  mat-label {
    font-size: 1rem;
    font-weight: $font-weight-bold;
    color: $dark-sapphire;
  }

  mat-error {
    display: block;
  }

  ct-input,
  ct-textarea {
    &[lightLabel='true'] {
      mat-label {
        font-size: 0.9375rem;
        font-weight: $font-weight-regular;
        color: $white;
      }
    }
  }

  .mat-mdc-form-field {
    color: $dark-charcoal;
    width: 100%;

    --mdc-typography-body1-line-height: 1.125rem;

    .mat-mdc-form-field-infix {
      border-top: none;
      min-height: auto;
    }

    &.mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
    }

    &.mat-form-field-appearance-outline .mdc-notched-outline__leading,
    &.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
      border-color: $light-grey;
    }

    &.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding: 1em 0 1em 0;
    }

    mat-error + mat-error {
      display: none;
    }
  }

  .mat-input-element::placeholder {
    color: $dark-charcoal;
  }

  .mat-form-field-invalid .mat-input-element {
    caret-color: $warn;
  }

  .mat-error {
    color: $warn;
    font-size: 0.875rem;
  }

  .mat-form-field-appearance-outline {
    .mdc-text-field--outlined {
      color: $nobel;
      background-color: $white;
      border-radius: 6px;
    }

    .mat-form-field-outline-thick {
      opacity: 1 !important;
    }

    &.mat-form-field-invalid .mat-form-field-outline-thick {
      color: $warn;
    }

    &.mat-focused .mat-form-field-outline-thick {
      color: $sapphire;
    }

    .mat-form-field-subscript-wrapper {
      padding: 0 !important;
      margin-top: 0.25em;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      cursor: pointer;
      top: 0.5em !important;
    }

    .mat-form-field-prefix {
      margin-right: 0.5rem;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(10%) translateX(25%) !important;
      height: auto;
    }
  }
}
