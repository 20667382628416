@import 'variables/color';

.ct-app {
  @media (min-width: 768px) {
    .container {
      max-width: 980px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 1180px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1535px;
    }
  }
}
