@import 'variables/color';

.ct-app {
  mat-stepper[orientation='vertical'] {
    .mat-vertical-stepper-header {
      pointer-events: none !important;
    }

    .mat-step-header.cdk-program-focused {
      background-color: transparent;
    }

    .mat-step-icon {
      height: 48px;
      width: 48px;
    }

    .mat-stepper-label-position-bottom,
    .mat-vertical-stepper-header {
      padding: 24px 0;
    }

    .mat-vertical-content-container {
      margin-left: 24px;
    }

    .mat-step-label {
      font-size: 1.125rem;
    }

    .mat-stepper-vertical-line::before {
      top: -4px;
      bottom: -4px;
    }
  }
}
