@import 'variables/color';
@import 'variables/font';

.ct-app {
  .mat-tab-header {
    margin-bottom: 1rem;
  }

  .mat-tab-label-content {
    font-size: 1rem;
    color: $dark-sapphire;
  }

  .mat-tab-label-active {
    opacity: 1;

    .mat-tab-label-content {
      color: $aero-blue;
      font-weight: $font-weight-semi-bold;
    }
  }
}
