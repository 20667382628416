@import 'variables/color';
@import 'variables/font';

.ct-app {
  .ct-dialog {
    position: relative !important;

    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        background-color: $light-grey;
        border-radius: 10px;
        padding: 24px 24px 0;
      }
    }

    &-content {
      &__close-icon {
        padding: 5px;
        line-height: 14px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        fill: $grey;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -24px;
        margin-bottom: 0;
        padding: 24px;
        background-color: $off-white;
        border-bottom: 1px solid $grey;

        &-icon {
          margin-right: 5px;
          line-height: 14px;
          width: 32px;
          height: 32px;
          cursor: pointer;
          fill: $dark-sapphire;
        }

        &-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &__icons {
        &-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &__title {
        font-size: 1.5rem;
        font-weight: $font-weight-regular;
        color: $dark-sapphire;
        margin-right: 1.5rem;
        margin-bottom: 0;
        padding: 0;

        &::before {
          height: auto;
        }
      }

      &__subtitle {
        font-size: 1rem;
        font-weight: $font-weight-regular;
        color: $dark-sapphire;
        padding-left: 1rem;
        border-left: 1px solid $dark-sapphire;
        height: 32px;
        display: flex;
        align-items: center;
      }

      &__actions {
        padding: 16px 0;
      }

      &__content {
        padding: 28px 32px 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .ct-dialog {
      width: 95vw !important;
      min-width: 95vw !important;
      max-width: 95vw !important;
      max-height: 95vh !important;

      &-content {
        &__content {
          padding: 8px 16px;
          max-height: 50vh;
        }
      }
    }
  }
}
