@import 'variables/color';
@import 'variables/font';

.ct-app {
  ct-gallery-item,
  ct-gallery-thumb {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    h2,
    h4 {
      color: $warn;
      margin: 0;
    }

    h2 {
      font-size: 3.5em;
      margin-bottom: 0.3em;
    }

    h4 {
      font-size: 1.6em;
    }
  }

  ct-gallery-item {
    z-index: 10;
    video,
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  ct-gallery[gallerize] {
    ct-gallery-item {
      cursor: pointer;
    }
  }

  .g-image-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }

  ct-gallery-thumb {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 300ms cubic-bezier(0.5, 0, 0.5, 1);
    &.g-active-thumb {
      opacity: 1;
    }
    .g-image-item {
      background-size: cover;
    }
  }

  .g-template,
  .g-image-error-message {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: $warn;
    font-weight: $font-weight-semi-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .g-loading {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
  }

  ct-gallery-core[imageSize='contain'] ct-gallery-slider .g-image-item {
    background-size: contain;
  }

  ct-gallery-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    mat-icon {
      height: inherit;
      width: inherit;
    }
  }

  ct-gallery-thumb ct-gallery-image {
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }
}
