.ct-app {
  .g-items-container {
    height: 100%;
  }

  .g-slider {
    position: absolute;
    transition: transform 400ms cubic-bezier(0.5, 0, 0.5, 1);
  }

  ct-gallery-core[slidingDirection='horizontal'] {
    .g-slider {
      flex-direction: row;
    }
  }

  ct-gallery-core[slidingDirection='vertical'] {
    .g-slider {
      flex-direction: column;
    }
  }
}
