$light-mustard: rgba(235, 150, 7, 0.28);
$mustard: #e17d00;
$orange: #f07700;
$orange-opacity: #f07700d9;
$dark-mustard: #d15500;

$light-aero-blue: #85bcef;
$aero-blue: #5d91da;
$dark-aero-blue: #4d6eb4;
$bleu-de-france: #2d95e3;

$gainsboro-grey: #d8d8d8;
$light-grey: #f4f4f4;
$grey: #c9c9c9;
$roman-silver: #888994;
$regent-gray: #8695a0;
$dark-grey: #7e7e7e;

$light-sapphire: #939ac0;
$sapphire: #2e3d86;
$dark-sapphire: #0a1551;

$off-white: #fafafa;
$white: #fff;
$black: #0f0f0f;
$dark-charcoal: #333;
$davys-gray: #54555e;
$nobel: #979797;
$lavender: #e6eef9;

$light-warn: #f73d50;
$warn: #ee0235;
$dark-warn: #cf0027;

$light-green: #a3da9d;
$green: #3db533;
$dark-green: #128311;
