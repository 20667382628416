.ct-app {
  .g-nav-next,
  .g-nav-prev {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 40px;
    cursor: pointer;
    z-index: 999;

    mat-icon {
      width: inherit;
      height: inherit;
    }
  }

  .g-nav-next {
    right: 0.5em;
    transform: translateY(-50%) perspective(1px);
  }

  .g-nav-prev {
    left: 0.5em;
    transform: translateY(-50%) perspective(1px) scale(-1, -1); //invert icon horizontally
  }

  @media only screen and (max-width: 480px) {
    .g-nav-next {
      right: 0.2em;
    }

    .g-nav-prev {
      left: 0.2em;
    }
  }
}
