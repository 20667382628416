@use 'sass:string';
@use 'avenir';
// @use '@fontsource/montserrat/scss/mixins' as Montserrat;
@import '@fontsource/montserrat/variable.css';
@import '@fontsource/montserrat/variable-italic.css';

/*
  Main font family & font-size
*/
$font-family: 'AvenirFont', 'MontserratVariable', sans-serif;
$font-size: 16px;

$additional-font-family: 'Georgia', 'Times New Roman';

/*
  Font weights
*/
$font-weight-light: 200;
$font-weight-semi-regular: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
