@import 'variables/color';

@mixin fullscreen() {
  width: 100%;

  ct-lightbox {
    max-width: unset;
    max-height: unset;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

.ct-app {
  ct-lightbox {
    position: relative;
    display: block;
    width: 1100px;
    height: 800px;
    max-width: 94vw;
    max-height: 90vh;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    &:focus {
      outline: none;
    }
    ct-gallery {
      overflow: hidden;
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $black;
    }
  }

  .g-backdrop {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .fullscreen {
    @include fullscreen();
  }

  .g-overlay {
    margin: auto;

    @media only screen and (max-width: 480px) {
      @include fullscreen();
    }
  }

  .g-btn-close {
    position: absolute;
    right: 0.9em;
    top: 0.9em;
    z-index: 60;
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: $white;
    @media only screen and (max-width: 480px) {
      right: 0.7em;
      top: 0.7em;
    }
  }

  .g-btn-edit {
    position: absolute;
    right: 2.9em;
    top: 0.9em;
    z-index: 60;
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: $white;
    @media only screen and (max-width: 480px) {
      right: 2.7em;
      top: 0.7em;
    }
  }
}
