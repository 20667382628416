@import 'variables/color';

.ct-app {
  ct-button button {
    display: block;

    &.mat-mdc-button.mat-mdc-button-base,
    &.mat-mdc-raised-button.mat-mdc-button-base,
    &.mat-mdc-unelevated-button.mat-mdc-button-base {
      height: 48px;
      padding: 0 1rem;
      background-color: white;
    }

    &.mat-mdc-outlined-button.mat-mdc-button-base {
      height: 48px;
      padding: 0 1rem;
      background-color: transparent;
    }

    &.mat-mdc-unelevated-button.mat-accent {
      --mdc-filled-button-label-text-color: white;

      background-color: $mustard;
    }

    &.mat-mdc-unelevated-button.mat-primary {
      --mdc-filled-button-label-text-color: white;

      background-color: $aero-blue;
    }

    &.mat-mdc-unelevated-button.mat-warn {
      --mdc-filled-button-label-text-color: white;

      background-color: $warn;
    }

    .mdc-button__label {
      text-transform: uppercase;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.mdc-button--outlined {
      border-width: 3px;
      border-style: solid;
      .mdc-button__label {
        color: $dark-grey;
      }
    }

    &.mdc-button--outlined.mat-primary {
      border-color: $dark-aero-blue;
      .mdc-button__label {
        color: $dark-aero-blue;
      }
    }

    &.mdc-button--outlined.mat-accent {
      border-color: $mustard;
      .mdc-button__label {
        color: $mustard;
      }
    }

    &.mdc-button--outlined.mat-warn {
      border-color: $warn;
      .mdc-button__label {
        color: $warn;
      }
    }

    &.mdc-button--outlined.mat-simple {
      border-color: $white;
      .mdc-button__label {
        color: $white;
      }
    }
  }
}
