@import 'variables/color';
@import 'variables/font';

$default-font-size: 1rem;

.ct-app {
  ct-text-editor-view {
    display: block;

    .ql-editor {
      padding: 0;
      display: block;
      width: 100%;
      color: $davys-gray !important;

      h2 {
        font-size: 1.75rem;
        line-height: 1.2;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 1.2rem;
        color: $davys-gray !important;

        * {
          color: $davys-gray !important;
        }
      }

      h3 {
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 1.5rem;
        color: $davys-gray !important;

        * {
          color: $davys-gray !important;
        }
      }

      p {
        margin-bottom: 1.2rem;
        font-size: 1rem;
        line-height: 1.5;
        color: $davys-gray !important;

        * {
          color: $davys-gray !important;
        }
      }

      a:not(:hover):not(:visited) {
        color: $bleu-de-france !important;
      }

      img {
        border-radius: 1rem;
      }
    }

    .ql-container {
      font-family: $font-family;
      font-size: $default-font-size;
    }
  }

  ct-text-editor {
    display: block;

    quill-editor {
      display: block;

      .ql-toolbar {
        border: none;
        padding: 0.5rem 0;

        .ql-picker-label {
          border: 1px solid $nobel;
          border-radius: 3px;
        }
      }

      .ql-editor {
        padding: 0.5rem 0;
        color: $davys-gray !important;

        h2 {
          font-size: 1.75rem;
          line-height: 1.2;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 1.2rem;
          color: $davys-gray !important;

          * {
            color: $davys-gray !important;
          }
        }

        h3 {
          font-size: 1.25rem;
          line-height: 1.2;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 1.5rem;
          color: $davys-gray !important;

          * {
            color: $davys-gray !important;
          }
        }

        p {
          margin-bottom: 1.2rem;
          font-size: 1rem;
          line-height: 1.5;
          color: $davys-gray !important;

          * {
            color: $davys-gray !important;
          }
        }

        a:not(:hover):not(:visited) {
          color: $bleu-de-france !important;
        }

        img {
          border-radius: 1rem;
        }
      }

      .ql-container {
        font-family: $font-family;
        font-size: $default-font-size;
        height: 300px;
        border: none;
        border-radius: 5px;
      }

      .ql-editor.ql-blank::before {
        font-style: normal;
        font-size: $default-font-size;
        color: $davys-gray;
        left: 2px;
      }

      .ql-tooltip {
        z-index: 30;
      }
    }
  }
}
