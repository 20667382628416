@font-face {
  font-family: 'AvenirFont';
  src: url('../fonts/avenir/Avenir-Book.woff2') format('woff2'), url('../fonts/avenir/Avenir-Book.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'AvenirFont';
  src: url('../fonts/avenir/Avenir-Black.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Black.woff') format('woff');
  font-weight: 800 900;
}

@font-face {
  font-family: 'AvenirFont';
  src: url('../fonts/avenir/Avenir-Heavy.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Heavy.woff') format('woff');
  font-weight: 600 700;
}

@font-face {
  font-family: 'AvenirFont';
  src: url('../fonts/avenir/Avenir-Medium.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Medium.woff') format('woff');
  font-weight: 400 500;
}

@font-face {
  font-family: 'AvenirFont';
  src: url('../fonts/avenir/Avenir-Light.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'AvenirRoman';
  src: url('../fonts/avenir/Avenir-Roman.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Roman.woff') format('woff');
  font-weight: normal;
}
